import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../consts/firebase'

export const Auth = params => {
    const { children } = params
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const AuthCheck = onAuthStateChanged(auth, (user) => {
            user
                ? setLoading(false)
                : navigate('/login')
        })
        return () => AuthCheck()
        // eslint-disable-next-line
    }, [auth])
    return loading
        ? <></> // Loading...
        : <>{children}</>
}
