import { doc, getDoc } from 'firebase/firestore'
import { db } from './firebase'

export const getDataFromDoc = async (auth) => {
    if (auth.currentUser !== null) {
        const snap = await getDoc(doc(db, 'users', auth.currentUser.uid))
        if (snap.exists()) {
            return snap.data()
        }
    }
}
