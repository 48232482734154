import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const app = initializeApp({
    apiKey: process.env.react_app_apiKey,
    authDomain: process.env.react_app_authDomain,
    projectId: process.env.react_app_projectId,
    storageBucket: process.env.react_app_storageBucket,
    messagingSenderId: process.env.react_app_messagingSenderId,
    appId: process.env.react_app_appId
})

const db = getFirestore(app)
const auth = getAuth()
export { db, auth }
