import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledMenuLink = styled(Link)`
    font-size: 0.75rem;
    text-transform: uppercase;
    color: white;
    text-decoration: none ;
    &:visited {
        color: white;
    }
    font-family: sans-serif;
`;
