import { getAdditionalUserInfo, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../consts/firebase'
import { addUser } from '../../consts/addUser'
import { useEffect, useState } from 'react'
import { FullScreenNotice } from './FullScreenNotice'
import { StyledLoginContainer } from './StyledLoginContainer'

export const Login = params => {
    const [fullScreenNoticeMessage, setFullScreenNoticeMessage] = useState('')
    useEffect(() => {
        let timer = setTimeout(() => { setFullScreenNoticeMessage('') }, 2000)
        return () => {
            clearTimeout(timer)
        }
    },
        [fullScreenNoticeMessage]
    )
    const navigate = useNavigate()
    const signIn = async () => {
        params.setLoggingIn(true)
        signInWithPopup(auth, new GoogleAuthProvider())
            .then(async (result) => {
                const { isNewUser } = getAdditionalUserInfo(result)
                if (isNewUser) {
                    result.user.delete().then(() => {
                        auth.signOut().then(() => {
                            setFullScreenNoticeMessage('Please sign up first!')
                            params.setLoggingIn(false)
                        })
                    });
                } else {
                    navigate('/')
                }
            })
            .catch(error => {
                params.setLoggingIn(false)
                // console.log(error)
            })
    }
    const signUp = async () => {
        params.setLoggingIn(true)
        signInWithPopup(auth, new GoogleAuthProvider())
            .then(async (result) => {
                const { isNewUser } = getAdditionalUserInfo(result)
                if (isNewUser) {
                    addUser(result.user)
                    params.setLoggingIn(false)
                    params.setAllowed(false)
                    navigate('/')
                } else {
                    auth.signOut().then(() => {
                        setFullScreenNoticeMessage("You are already a user - please sign in!")
                        params.setLoggingIn(false)
                        params.setAllowed(false)
                    })
                }
            })
            .catch(_error => {
                params.setLoggingIn(false)
                // console.log(error)
            })
    }
    return fullScreenNoticeMessage.length > 0 ? <FullScreenNotice>{fullScreenNoticeMessage}</FullScreenNotice>
        : (
            <StyledLoginContainer>
                <input onChange={(e) => {
                    params.setAllowed({ ...params.allowed, notFromUSorCanada: e.target.checked })
                }} type={'checkbox'} disabled={params.loggingIn} /> I'm not living in USA or Canada
                <br />
                <input onChange={(e) => {
                    params.setAllowed({ ...params.allowed, olderThan18: e.target.checked })
                }} type={'checkbox'} disabled={params.loggingIn} /> I'm at least 18 years old
                <br />
                <input onChange={(e) => {
                    params.setAllowed({ ...params.allowed, privacy: e.target.checked })
                }} type={'checkbox'} disabled={params.loggingIn} /> I'm accepting the <Link to='/privacypolicy'>privacy policy</Link>
                <br />
                <br />
                <button onClick={() => {
                    signUp(params)
                }} disabled={params.loggingIn || (!(params.allowed.olderThan18 && params.allowed.notFromUSorCanada && params.allowed.privacy))}>Sign up with Google</button>
                <>{' '}</>
                <button onClick={() => {
                    signIn()
                }} disabled={params.loggingIn}>Login with Google</button>
                <br />
            </StyledLoginContainer>
        )
}
