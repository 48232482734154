import { isEmpty } from '@firebase/util'
import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../consts/firebase'
import { getDataFromDoc } from '../../consts/getDataFromDoc'
import { timespan } from '../../consts/timespan'
import { StyledAccountInfoContainer } from './StyledAccountInfoContainer'
import { StyledAccountInfoTitle } from './StyledAccountInfoTitle'

export const AccountInfo = () => {
  const [user, loading] = useAuthState(auth)
  const [userData, setUserData] = useState({})
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate()
  const fetchUserName = async () => {
    try {
      const doc = await getDataFromDoc(auth)
      setUserData({
        name: doc.name,
        roles: [doc.roles]
      });
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (loading) return
    if (!user) return navigate('/')
    fetchUserName()
    // eslint-disable-next-line
  }, [user, loading])

  let timespanSinceLogon = timespan(user.metadata.lastLoginAt)
  let timespanSinceCreation = timespan(user.metadata.createdAt)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1)
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div>
      {
        isEmpty(userData)
          ? <></> // Loading...
          : (
            <StyledAccountInfoContainer>
              <div><StyledAccountInfoTitle>Logged in as: </StyledAccountInfoTitle></div>
              <div><StyledAccountInfoTitle>Mail: </StyledAccountInfoTitle>{user.email}</div>
              <div><StyledAccountInfoTitle>Name: </StyledAccountInfoTitle>{userData.name}</div>
              <div><StyledAccountInfoTitle>Verified e-mail: </StyledAccountInfoTitle>{String(user.auth.currentUser.emailVerified)}</div>
              <div><StyledAccountInfoTitle>Roles: </StyledAccountInfoTitle>{userData.roles.join(',')}</div>
              <div><StyledAccountInfoTitle>User created at: </StyledAccountInfoTitle>{user.metadata.creationTime}</div>
              <div><StyledAccountInfoTitle>Last logon: </StyledAccountInfoTitle>{user.metadata.lastSignInTime}</div>
              <div><StyledAccountInfoTitle>TimeZone: </StyledAccountInfoTitle>{Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
              <div><StyledAccountInfoTitle>Time is: </StyledAccountInfoTitle>{String(new Date())}</div>
              <div><StyledAccountInfoTitle>Hours from TimeZone to GMT: </StyledAccountInfoTitle>{(new Date().getTimezoneOffset()) / 60}</div>
              <div><StyledAccountInfoTitle>Ticks from TimeZone to GMT: </StyledAccountInfoTitle>{(new Date().getTimezoneOffset()) / 60 * 3600000}</div>
              <div><StyledAccountInfoTitle>Time since last logon:</StyledAccountInfoTitle> {Math.round(timespanSinceLogon.days)} days {timespanSinceLogon.time.getHours()} hours {timespanSinceLogon.time.getMinutes()} minutes and {timespanSinceLogon.time.getSeconds()} seconds</div>
              <div><StyledAccountInfoTitle>Account has existed in:</StyledAccountInfoTitle> {Math.round(timespanSinceCreation.days)} days {timespanSinceCreation.time.getHours()} hours {timespanSinceCreation.time.getMinutes()} minutes and {timespanSinceCreation.time.getSeconds()} seconds</div>
              <div><StyledAccountInfoTitle>Language: </StyledAccountInfoTitle>{navigator.language}</div>
              <div><StyledAccountInfoTitle>Language: </StyledAccountInfoTitle>{navigator.languages.join(', ')}</div>
            </StyledAccountInfoContainer>
          )
      }
    </div>
  )
}
