import { useEffect, useState } from 'react'
import { logOffAndResetState } from '../../consts/logOffAndResetState'
import { logOffAndRemoveAndResetState } from '../../consts/logOffAndRemoveAndResetState';
import { StyledMenuContainer } from './StyledMenuContainer';
import { StyledMenuLink } from './StyledMenuLink';

export const Menu = (params) => {
    const [userWouldLikeToLogOff, setUserWouldLikeToLogOff] = useState(false)
    const [userWouldLikeToLBeRemoved, setUserWouldLikeToLBeRemoved] = useState(false)
    useEffect(() => {
        userWouldLikeToLogOff ? (
            logOffAndResetState(params)
        ) : (
            <></>
        )
        userWouldLikeToLBeRemoved ? (
            logOffAndRemoveAndResetState(params)
        ) : (
            <></>
        )
        // eslint-disable-next-line
    }, [userWouldLikeToLogOff, userWouldLikeToLBeRemoved])
    return <StyledMenuContainer>
        <StyledMenuLink to='/'>home</StyledMenuLink>
        <StyledMenuLink to='/accountinfo'>account info</StyledMenuLink>
        <StyledMenuLink onClick={() => {
            setUserWouldLikeToLBeRemoved(true)
        }} to='/'>remove account</StyledMenuLink>
        <StyledMenuLink onClick={() => {
            setUserWouldLikeToLogOff(true)
        }} to='/'>log off</StyledMenuLink>
    </StyledMenuContainer>
}
