import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Auth } from './components/Login/Auth'
import { AccountInfo } from './components/AccountInfo/AccountInfo'
import { Login } from './components/Login/Login'
import { PrivacyPolicy } from './components/Login/PrivacyPolicy'
import { Menu } from './components/Menu/Menu'
import { useState } from "react"
import { StyledHomeContainer } from './StyledHomeContainer'
export const App = () => {
    const [loggingIn, setLoggingIn] = useState(false)
    const [allowed, setAllowed] = useState({
        olderThan18: false,
        notFromUSorCanada: false,
        privacy: false
    })
    return <>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={
                    <Auth>
                        <Menu
                            setLoggingIn={setLoggingIn}
                            setAllowed={setAllowed} />
                        <StyledHomeContainer>
                            <>Welcome to DatazDirect</>
                        </StyledHomeContainer>
                    </Auth>
                } />
                <Route path='/accountinfo' element={
                    <Auth>
                        <Menu
                            setLoggingIn={setLoggingIn}
                            setAllowed={setAllowed} />
                        <AccountInfo />
                    </Auth>
                } />
                <Route path='/privacypolicy' element={
                    <PrivacyPolicy />
                } />
                <Route path='/login' element={
                    <>
                        <Login
                            setLoggingIn={setLoggingIn}
                            loggingIn={loggingIn}
                            setAllowed={setAllowed}
                            allowed={allowed}
                        />
                    </>
                } />
            </Routes>
        </BrowserRouter>
    </>
}
