import { auth } from './firebase';

export const logOffAndRemoveAndResetState = (params) => {
    params.setLoggingIn(false);
    params.setAllowed({
        olderThan18: false,
        notFromUSorCanada: false,
        privacy: false
    })
    auth.currentUser.delete();
};
