import { doc, setDoc } from 'firebase/firestore';
import { db } from './firebase';

export const addUser = async (user) => {
    const userRef = doc(db, 'users', user.uid);
    return await setDoc(userRef, {
        name: user.displayName,
        roles: ['standard']
    });
};
