import styled from 'styled-components';

export const FullScreenNotice = styled.div`
    text-align: center;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
`;
